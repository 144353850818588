import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import video from "../images/splash.mp4"
import logo from "../images/logo.png"

import { motion, AnimatePresence } from "framer-motion"

const titleCard = (
  <div className="title-card-container">
    <div className="img-container" style={{ marginBottom: `3rem` }}>
      <img src={logo} alt="Nelda Technologies Logo" />
    </div>
    <p>Coming 2021</p>
  </div>
)

const videoContainer = (
  <div className="video-container">
    <video autoPlay muted>
      <source src={video} type="video/mp4" />
    </video>
  </div>
)

const IndexPage = () => {
  let [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    let loadingTimer = setTimeout(() => {
      setLoading(false)
    }, 2500)

    return () => {
      clearTimeout(loadingTimer)
    }
  }, [])
  return (
    <Layout>
      <SEO title="Coming Soon" />
      <div className="container">
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={loading}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            {loading ? titleCard : videoContainer}
          </motion.div>
        </AnimatePresence>
      </div>
    </Layout>
  )
}

export default IndexPage
